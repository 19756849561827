<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Teacher Class Mapping
          <div class="card-header-actions">
              <CButton color="primary" @click="map_teacher" >Map + </CButton>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
              <b-row>
                <b-col sm="4">
                    <b-input-group size="sm">
                        <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        ></b-form-input>
                    </b-input-group>
                </b-col>
                <b-col offset-sm="5" sm="3">
                    Total Records : {{totalRows}}
                </b-col>
            </b-row><br/>
            
            <div class="table-responsive" style="font-size:14px;font-family: Arial, Helvetica, sans-serif;">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    stacked="xs"
                    show-empty
                    small
                    @filtered="onFiltered"
                    >
                  </b-table>
                    
            </div><br/>
            <b-row>
                <b-col sm="2">
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                    ></b-pagination>
                </b-col>
                
                <b-col  offset-sm="7"  sm="3">
                    <b-form-group horizontal label="Per page" label-size="sm" :label-cols="6" >
                    <b-form-select :options="pageOptions" v-model="perPage" size="sm"/>
                    </b-form-group>
                </b-col>
            </b-row>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
    form: {
       class_id:""
      },
      show: true,
      isCollapsed: true,
       items: [
         { techaer_name: 'Ms. Aruna Patil',class_standard:2,subject_id:'Math',division:'B',ac_year:2021 },
         { techaer_name: 'Ms. Vinit Patil',class_standard:2,subject_id:'English',division:'B',ac_year:2021 }
        ],
        fields: [
          { key: 'techaer_name', label: 'Teacher', sortable: true, class: 'class1'},
          { key: 'class_standard', label: 'Class', sortable: true, class: 'class2'},
          { key: 'subject', label: 'Subject', sortable: true, class: 'class3'},
          { key: 'division', label: 'Division', sortable: true, class: 'class3'},
          { key: 'ac_year', label: 'Year', sortable: true, class: 'class3'},
        //  { key: 'actions', label: 'Action' }
        ],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn:[]

    };
  },
  methods:{
      map_teacher(){
          this.$router.push('/teacher_class');
      },
      get_teacher_class(){
            axios.get('https://localhost:44331/api/TeacherSubjectMappings/').then(response => {
        //  axios.get('https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api/Contents/').then(response => {
              console.log(response);
              this.items = response.data;
              this.totalRows = this.items.length();
          }).catch(error => {
              console.log(error);
          })
      },
      onSubmit(event) {
        event.preventDefault();
        if(this.para == '' || this.para == undefined){
            var data = {
              'class_standard': parseInt(this.form.class_id)
            };
            console.log(data);
            var promise = apis.add_ClassMasters(data)
            .then( response => {
              this.$alert("Class Added Sucessfully");
              this.$router.go(-1);
            }).catch(error => {

            });
          
        }else{
            // edit API will be call
             var data = {
               'school_id': this.id,
                'school_name': this.form.schoolName,
                'address': this.form.schoolAddress,
                'contact_no': this.form.contact,
                'contact_person': this.form.contactPerson,
                'district_id': '00000000-0000-0000-0000-000000000000'
            };
            console.log(data);
            axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
              this.isLoading = false;
              
              this.$alert("School Updated Sucessfully");
              this.$router.go(-1);

            }).catch(error => {

            });
        }
           
    },
  
  },
  created(){
    this.get_teacher_class();
  }
};
</script>
